//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VueSlickCarousel from "vue-slick-carousel";
import { imageUrlApi } from "@/config";
export default {
  props: ["product_list"],
  components: {
    VueSlickCarousel: VueSlickCarousel
  },
  data: function data() {
    return {
      swiping: false,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      }
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    setCarouselSwiping: function setCarouselSwiping(state) {
      this.swiping = state;
    }
  }
};