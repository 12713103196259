//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OVERVIEW } from "@/core/services/store/profile.module.js";
import UserLevelPrice from "./components/UserLevelPrice.vue";
import TrackingDashboard from "./components/TrackingDashboard.vue";
import AddressChina from "./components/AddressChina.vue";
import ProductDashboard from "./components/ProductDashboard.vue";
import { WEBSITE } from "@/config";
export default {
  name: "DashboardBase",
  components: {
    UserLevelPrice: UserLevelPrice,
    TrackingDashboard: TrackingDashboard,
    AddressChina: AddressChina,
    ProductDashboard: ProductDashboard
  },
  data: function data() {
    return {
      loading: false,
      overview: {}
    };
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "แดชบอร์ด"
    }]);
    document.title = "\u0E41\u0E14\u0E0A\u0E1A\u0E2D\u0E23\u0E4C\u0E14 | ".concat(WEBSITE);
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(OVERVIEW).then(function (_ref) {
        var code = _ref.code,
            data = _ref.data;
        _this.loading = false;

        if (code === 200) {
          _this.overview = data;
          _this.isSuccess = true;
        } else {
          _this.isSuccess = false;
        }
      }, function () {
        _this.loading = false;
      });
    },
    setActiveTab1: function setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2: function setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab: function setActiveTab(event) {
      // get all tab links
      var tab = event.target.closest('[role="tablist"]');
      var links = tab.querySelectorAll(".nav-link"); // remove active tab links

      for (var i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      } // set current active tab


      event.target.classList.add("active"); // set clicked tab index to bootstrap tab

      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};